<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">历史统计分析</h1>
    </div>
    <div slot="extra">
      <a-form layout="inline">
        <a-form-item label="日期范围">
          <a-range-picker style="width: 260px;" :allowClear="false"
                          format="YYYY-MM-DD"
                          :value="defaultHistoryTime"
                          @change="handleModalPickerOk"></a-range-picker>
        </a-form-item>
        <a-form-item class="f-r m-r-none">
          <a-button type="primary" class="m-r-12" @click="searchByData('sevenDays')">近7日</a-button>
          <a-button type="primary" class="m-r-12" @click="searchByData('currentWeek')">本周</a-button>
          <a-button type="primary" class="m-r-12" @click="searchByData('thirtyDays')">近30日</a-button>
          <a-button type="primary" @click="searchByData('currentMonth')">本月</a-button>
        </a-form-item>
      </a-form>
    </div>
    <a-card :bordered="false">
      <a-spin :spinning="spinLoading" tip="加载中...">
        <a-row :gutter="24">
          <a-col :sm="24" :md="12" :lg="6" :xl="6">
            <a-card hoverable @click="currentChartClick('loop')"
                    :style="{ background: currentChart==='loop'?'#d6f7e8':'#ffffff' }">
              <template>
                <a-row type="flex" style="text-align: center; color: grey;font-size: 18px;line-height: 30px;">
                  <a-col :span="24">
                    清运趟数
                  </a-col>
                </a-row>
                <a-row type="flex" style="text-align: center; color: black; font-size: 23px">
                  <a-col :span="24">
                    {{totalLoop}}&nbsp;<span style="font-size: 12px">趟</span>
                  </a-col>
                </a-row>
              </template>
            </a-card>
          </a-col>
          <a-col :sm="24" :md="12" :lg="6" :xl="6">
            <a-card hoverable @click="currentChartClick('ton')"
                    :style="{ background: currentChart==='ton'?'#d6f7e8':'#ffffff' }">
              <template>
                <a-row type="flex" style="text-align: center; color: grey;font-size: 18px;line-height: 30px;">
                  <a-col :span="24">
                    拉运吨数
                  </a-col>
                </a-row>
                <a-row type="flex" style="text-align: center; color: black; font-size: 23px;">
                  <a-col :span="24">
                    {{totalTon}}&nbsp;<span style="font-size: 12px">吨</span>
                  </a-col>
                </a-row>
              </template>
            </a-card>
          </a-col>
          <a-col :sm="24" :md="12" :lg="6" :xl="6">
            <a-card hoverable @click="currentChartClick('task')"
                    :style="{ background: currentChart==='task'?'#d6f7e8':'#ffffff' }">
              <template>
                <a-row type="flex" style="text-align: center; color: grey;font-size: 18px;line-height: 30px;">
                  <a-col :span="24">
                    任务个数
                  </a-col>
                </a-row>
                <a-row type="flex" style="text-align: center; color: black; font-size: 23px">
                  <a-col :span="24">
                    {{totalTask}}&nbsp;<span style="font-size: 12px">个</span>
                  </a-col>
                </a-row>
              </template>
            </a-card>
          </a-col>
          <a-col :sm="24" :md="12" :lg="6" :xl="6">
            <a-card hoverable @click="currentChartClick('mileage')"
                    :style="{ background: currentChart==='mileage'?'#d6f7e8':'#ffffff' }">
              <template>
                <a-row type="flex" style="text-align: center; color: grey;font-size: 18px;line-height: 30px;">
                  <a-col :span="24">
                    行驶公里
                  </a-col>
                </a-row>
                <a-row type="flex" style="text-align: center; color: black; font-size: 23px">
                  <a-col :span="24">
                    {{totalMileage}}&nbsp;<span style="font-size: 12px">公里</span>
                  </a-col>
                </a-row>
              </template>
            </a-card>
          </a-col>
        </a-row>
        <a-row :gutter="32" style="margin-top: 16px;">
          <a-col :sm="18" :md="18" :lg="18" :xl="18">
            <div style="margin: 10px 0;font-size: 18px;font-weight: bold;">
              <a-icon type="bar-chart"/>&nbsp;日统计趋势
            </div>
            <div id="loopHistory"></div>
          </a-col>
          <a-col :sm="6" :md="6" :lg="6" :xl="68">
            <div style="margin: 10px 0;font-size: 18px;font-weight: bold;">
              <a-icon type="fund"/>&nbsp;清运排行榜
            </div>
            <div style="height: 280px;overflow: auto;padding-right: 10px;">
              <rank-list title="清运排行榜" :list="rankList"/>
            </div>
          </a-col>
        </a-row>
        <a-row :gutter="24" style="margin-top: 6px;">
          <a-col :sm="24" :md="12" :lg="12" :xl="12">
            <a-row>
              <div style="margin: 10px 0;font-size: 18px;font-weight: bold;">
                <a-icon type="car"/>&nbsp;车辆综合统计
              </div>
              <div class="ant-table-wrapper">
                <div class="ant-spin-nested-loading">
                  <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                    <div class="ant-table-content">
                      <div class="ant-table-body">
                        <table style="text-align: center;width: 100%">
                          <thead class="ant-table-thead">
                          <tr>
                            <th style="text-align: center;width: 130px">车辆牌号</th>
                            <th style="text-align: center;width: 130px">拉运（趟）</th>
                            <th style="text-align: center;width: 130px">数量（吨）</th>
                            <th style="text-align: center;width: 130px">任务（个）</th>
                            <th style="text-align: center;width: 130px">行驶（公里）</th>
                          </tr>
                          </thead>
                          <tbody class="ant-table-tbody">
                          <tr class="ant-table-row ant-table-row-level-0" v-for="item in carInfoGatherList">
                            <td class="ant-table-row-cell-break-word">{{item.carPlatNumber}}</td>
                            <td class="ant-table-row-cell-break-word">{{item.loopNum}}</td>
                            <td class="ant-table-row-cell-break-word">{{item.tonNum}}</td>
                            <td class="ant-table-row-cell-break-word">{{item.taskNum}}</td>
                            <td class="ant-table-row-cell-break-word">{{item.mileageNum}}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a-row>
          </a-col>
          <a-col :sm="24" :md="12" :lg="12" :xl="12">
            <a-row>
              <div style="margin: 10px 0;font-size: 18px;font-weight: bold;">
                <a-icon type="user"/>&nbsp;司机综合统计
              </div>
              <div class="ant-table-wrapper">
                <div class="ant-spin-nested-loading">
                  <div class="ant-table ant-table-scroll-position-left ant-table-default ant-table-bordered">
                    <div class="ant-table-content">
                      <div class="ant-table-body">
                        <table style="text-align: center;width: 100%">
                          <thead class="ant-table-thead">
                          <tr>
                            <th style="text-align: center;width: 130px">司机姓名</th>
                            <th style="text-align: center;width: 130px">拉运（趟）</th>
                            <th style="text-align: center;width: 130px">数量（吨）</th>
                            <th style="text-align: center;width: 130px">任务（个）</th>
                            <th style="text-align: center;width: 130px">行驶（公里）</th>
                          </tr>
                          </thead>
                          <tbody class="ant-table-tbody">
                          <tr class="ant-table-row ant-table-row-level-0" v-for="item in driverGatherList">
                            <td class="ant-table-row-cell-break-word">{{item.driverName}}</td>
                            <td class="ant-table-row-cell-break-word">{{item.loopNum}}</td>
                            <td class="ant-table-row-cell-break-word">{{item.tonNum}}</td>
                            <td class="ant-table-row-cell-break-word">{{item.taskNum}}</td>
                            <td class="ant-table-row-cell-break-word">{{item.mileageNum}}</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a-row>
          </a-col>
        </a-row>
      </a-spin>
    </a-card>
  </page-layout>
</template>


<script>
  import PageLayout from '@/components/page/PageLayout'
  import RankList from '@/components/chart/RankList'
  import G2 from '@antv/g2'
  import moment from 'moment'
  import SERVICE_URLS from '@/api/service.url'

  export default {
    name: 'LoopHistory',
    components: { PageLayout, RankList },
    data () {
      return {
        spinLoading: false,
        currentChart: 'loop',
        searchParams: {
          startTime: null,
          endTime: null,
          chartType: 'loop'
        },
        defaultHistoryTime: null,
        dateSearch: null,
        carInfoGatherList: [],
        driverGatherList: [],
        totalLoop: '',
        totalTon: '',
        totalTask: '',
        totalMileage: '',
        chart: null,
        data: [],
        rankList: []
      }
    },
    mounted () {
      this.searchParams.startTime = moment().subtract(29, 'days').format('YYYY-MM-DD')
      this.searchParams.endTime = moment(new Date()).format('YYYY-MM-DD')
      this.defaultHistoryTime = [this.searchParams.startTime, this.searchParams.endTime]
      this.search()
      this.initChart()
    },
    methods: {
      searchByData (type) {
        if (type === 'sevenDays') {
          this.searchParams.startTime = moment().subtract(7, 'days').format('YYYY-MM-DD')
          this.searchParams.endTime = moment().format('YYYY-MM-DD')
          this.defaultHistoryTime = [this.searchParams.startTime, this.searchParams.endTime]
        } else if (type === 'currentWeek') {
          this.searchParams.startTime = moment().startOf('week').format('YYYY-MM-DD')
          this.searchParams.endTime = moment().format('YYYY-MM-DD')
          this.defaultHistoryTime = [this.searchParams.startTime, this.searchParams.endTime]
        } else if (type === 'thirtyDays') {
          this.searchParams.startTime = moment().subtract(30, 'days').format('YYYY-MM-DD')
          this.searchParams.endTime = moment().format('YYYY-MM-DD')
          this.defaultHistoryTime = [this.searchParams.startTime, this.searchParams.endTime]
        } else if (type === 'currentMonth') {
          this.searchParams.startTime = moment().startOf('month').format('YYYY-MM-DD')
          this.searchParams.endTime = moment().format('YYYY-MM-DD')
          this.defaultHistoryTime = [this.searchParams.startTime, this.searchParams.endTime]
        }
        this.search()
      },
      search () {
        this.loadData()
        this.loadChartData()
      },
      //统计块点击
      currentChartClick (type) {
        this.searchParams.chartType = type
        this.currentChart = type
        this.loadChartData()
      },
      handleModalPickerOk (value) {
        if (value.length > 0) {
          this.searchParams.startTime = value[0].format('YYYY-MM-DD')
          this.searchParams.endTime = value[1].format('YYYY-MM-DD')
        } else {
          this.searchParams.startTime = ''
          this.searchParams.endTime = ''
        }
        this.defaultHistoryTime = [this.searchParams.startTime, this.searchParams.endTime]
        this.search()
      },
      loadData () {
        this.spinLoading = true
        this.$http(this, {
          url: SERVICE_URLS.csgz.dispatchAppApi.dispatchHistoryStatistics,
          data: this.searchParams,
          noTips: true,
          success: (data) => {
            this.totalLoop = data.body.totalLoop
            this.totalTon = data.body.totalTon
            this.totalTask = data.body.totalTask
            this.totalMileage = data.body.totalMileage
            this.carInfoGatherList = data.body.carInfoGatherList
            this.driverGatherList = data.body.driverGatherList
            this.spinLoading = false
          }
        })
      },
      loadChartData () {
        this.$http(this, {
          url: SERVICE_URLS.csgz.dispatchAppApi.dispatchHistoryChart,
          data: this.searchParams,
          noTips: true,
          success: (data) => {
            this.data = data.body
            this.spinLoading = false
            this.chart.source(this.data)
            this.chart.repaint()

            //清运排行榜
            this.rankList = []
            var unit = ''
            if (this.currentChart === 'loop') {
              unit = '趟'
            } else if (this.currentChart === 'ton') {
              unit = '吨'
            } else if (this.currentChart === 'task') {
              unit = '个'
            } else if (this.currentChart === 'mileage') {
              unit = '公里'
            }
            //排序
            this.data.sort(function (a, b) {
              return b.value - a.value
            })
            for (let i = 0; i < this.data.length; i++) {
              this.rankList.push({
                name: this.data[i].date,
                total: this.data[i].value + ' ' + unit
              })
            }
          }
        })
      },
      initChart () {
        const chart = new G2.Chart({
          container: 'loopHistory',
          forceFit: true,
          height: 300,
          padding: [50, 50, 50, 50]
        })
        chart.source(this.data)
        chart.tooltip({
          showTitle: true
        })
        chart.axis('date', {
          tickLine: {
            alignTick: false
          }
        })
        chart.axis('value', false)
        /*chart.axis('value', {
          label: {
            formatter: val => {
              var unit = ''
              if (this.currentChart === 'loop') {
                unit = '趟'
              } else if (this.currentChart === 'ton') {
                unit = '吨'
              } else if (this.currentChart === 'task') {
                unit = '个'
              } else if (this.currentChart === 'mileage') {
                unit = '公里'
              }
              return val + unit
            }
          }
        })*/
        chart.legend(false)
        chart.intervalStack()
          .position('date*value')
          .tooltip('date*value', (item, count) => {
            var name = ''
            var unit = ''
            if (this.currentChart === 'loop') {
              name = '清运'
              unit = '趟'
            } else if (this.currentChart === 'ton') {
              name = '数量'
              unit = '吨'
            } else if (this.currentChart === 'task') {
              name = '任务'
              unit = '个'
            } else if (this.currentChart === 'mileage') {
              name = '行驶'
              unit = '公里'
            }
            return {
              name: name,
              value: count + unit
            }
          })
        chart.point().position('date*value').label('value', {
          content: (originData) => {
            var unit = ''
            if (this.currentChart === 'loop') {
              unit = '趟'
            } else if (this.currentChart === 'ton') {
              unit = '吨'
            } else if (this.currentChart === 'task') {
              unit = '个'
            } else if (this.currentChart === 'mileage') {
              unit = '公里'
            }
            return originData + unit
          },
          offset: 20
        })
        this.chart = chart
        this.chart.render()
      }
    }
  }
</script>